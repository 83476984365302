.text-field__input-container {
    margin-bottom: 18px;
}

.text-field__input {
    width: 100%;
    background-color: unset;
    padding: 0 12px;
    border: none;
    outline: none;
    height: 46px;
    border: 1px solid #000;
    border-radius: 36px;
}
