.log-in {
    background-color: #FFFFFF;
}

.log-in__element-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 30px);
    justify-content: space-around;
}

.log-in__device-logo {
    height: 154px;
    margin-top: 55px;
}

.log-in__login-in-button {
    width: 155px;
    margin-bottom: 24px;
}

.log-in__copyright-footer {
    display: flex;
    justify-content: center;
    font-size: 10px;
    text-transform: uppercase;
}
