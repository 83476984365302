@import '../../../theme';

.button {
    border: 1px solid transparent;
    padding: 4px;
    font-size: inherit;
    cursor: pointer;
    z-index: unset !important;
    background-color: unset;
    width: 100%;
    min-width: 42px;

    user-select: none;
    /* standard */
    -moz-user-select: none;
    /* firefox specific */
    -webkit-user-select: none;
    /* Chrome, Opera and Safari*/
    -ms-user-select: none;
    /* IE, ms-edge */
    text-transform: uppercase;
}

.button>svg {
    fill: black;
}

.button--active {
    border: 1px solid black;
    border-radius: 50%;
}

.button--disabled>svg {
    fill: gray;
}

.button--disabled {
    cursor: default;
}

.button--primary {
    border-radius: 32px;
    border: 1.72px solid #1E1E1E;
    background: rgba(30, 30, 30, 0.00);
    padding: 10px 20px;
    color: black;
}

.button--primary-white {
    border-radius: 32px;
    border: 1.72px solid #FFFFFF;
    background: rgba(30, 30, 30, 0.00);
    padding: 10px 20px;
    color: #FFF;
}

.button--secondary {
    border-radius: 32px;
    border: 0.725px solid #131313;
    background: #040404;
    color: #FFF;
    padding: 10px 20px;
}

.button--secondary-white {
    border-radius: 32px;
    border: 1.72px solid #FFFFFF;
    background: #FFF;
    padding: 15px 20px;
    color: #565555;
}

.button--warning {
    border-radius: 32px;
    border: 1.72px solid #DEDEDE;
    background: #F00;
    color: #FFF;
    padding: 15px 20px;
}

.button--no-border {
    border: none;
}

.button__icon {
    height: 24px;
    position: absolute;
    display: flex;
    margin-right: 24px;
}

.button__icon > svg {
    height: 24px;
    width: 100%;
}

.button--medium {
    font-size: 12px;
}

.button--large {
    font-size: 18px;
}