.home__password-input-container {
    margin-bottom: 16px;
}

.home__password-input {
    background-color: unset;
    padding: 0;
    border: none;
    outline: none;
    height: 50px;
    border: 1px solid #000;
    border-radius: 36px;
}

.site-password-gate__error {
    text-align: center;
}