.home {
    background-color: #FFFFFF;
}

.home__element-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 30px);
    justify-content: space-around;
}

.home__device-logo {
    height: 154px;
    margin-top: 55px;
}

.home__button-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.home__button {
    margin-bottom: 32px;
    width: 220px;

    button {
        text-transform: none;
    }
}

.home__copyright-footer {
    display: flex;
    justify-content: center;
    font-size: 10px;
    text-transform: uppercase;
}

.home__email-contact {
    position: absolute;
    transform: rotate(-90deg);
    right: -70px;
    top: 75%;
}

.home__password-input {
    margin-bottom: 6px;
    text-align: center;

    input {
        text-align: center;
        border-radius: 36px;
        padding: 5px 0;
    }


    ::placeholder {
        text-align: center;
    }

    /* or, for legacy browsers */

    ::-webkit-input-placeholder {
        text-align: center;
    }

    :-moz-placeholder {
        /* Firefox 18- */
        text-align: center;
    }

    ::-moz-placeholder {
        /* Firefox 19+ */
        text-align: center;
    }

    :-ms-input-placeholder {
        text-align: center;
    }
}

.home__contact-container {
    position: relative;
}

.home__contact-container > button {
    text-decoration: underline;
    color: #565555;
}

.home__disclaimer-text {
    font-size: 12px;
    text-align: justify;
    max-width: 665px;
}