p {
  margin-bottom: unset !important;
}

.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #1E1E1E;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.App-content-container {
  overflow: hidden;
  overflow-y: auto;
}

.chat-messages-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Add a vertical scrollbar when needed */
  overflow-x: hidden;
  padding-bottom: 0px; /* Optional: Add some bottom padding to separate the form from the messages */
  height: 100%;
  padding: 0 !important;
}

.chat-messages-row {
  height: calc(100% - 85px);
}

.App-message-container {
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

.dark-mode {
  background: #1E1E1E;
}