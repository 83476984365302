// .landing-page {
//     background: radial-gradient(213.21% 126.06% at 50% 50%, #EBE9DE 0%, #FFF4FC 100%);
// }

.landing-page__header {
    display: flex;
    justify-content: center;
    margin-top: 18px;
    color: #BBB6B2;
}

.landing-page__element-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 160px);
    justify-content: space-around;
}

.landing-page__pearl-logo {
    pointer-events: none;
}

.landing-page__footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;
}

.landing-page__disclaimer {
    font-size: 12px;
    text-align: justify;
    max-width: 665px;
    margin-bottom: 16px;
    padding: 0 15px;
}

.landing-page__copyright {
    font-size: 12px;
    text-transform: uppercase;
    color: #565555;
}

.landing-page__terms-and-conditions {
    display: flex;
    justify-content: center;
    font-size: 10px;
    text-transform: uppercase;
    margin-bottom: 12px;
}

.landing-page__link {
    text-decoration: underline;
    color: #525252;
}

.landing-page__link-seperator {
    margin-left: 4px;
    margin-right: 4px;
}