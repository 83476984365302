@import '../theme';

.message-container {
    margin-bottom: 8px;
    height: 100%;
    padding: 0px 16px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
}

.message-container__scroll-container {
    height: 100%;
    padding: 0 !important;
}

.message-container__scroll-container>div {
    display: flex;
    flex-direction: column;
}

.message-container__messages {
    margin-top: auto;
    padding-top: 84px; // nav-bar height
    padding-left: 16px;
    padding-right: 16px;
}

.message-container__fetching-history {
    text-align: center;
    font-size: small;
    font-style: oblique;
    opacity: 0.5;
}

.message-container__spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.message-container__spinner {
    border-color: $color-primary !important;
    border-right-color: transparent !important;
}

.message-container__loading-dots {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
}