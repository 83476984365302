.tour-guide {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tour-guide__paragraph {
    margin-bottom: 36px;
}

.tour-guide__segment-container {
    @media (min-width:641px) {
        width: 430px;
    }
    padding-top: 45px;
    padding-bottom: 45px;
    margin-top: auto;
    margin-left: 45px;
    margin-right: 45px;
}

.tour-guide__top-bar{
    @media (min-width:641px) {
        width: 430px;
    }
    position:fixed;
    width: 100%;
    display: flex;
    justify-content: end;
}

.tour-guide__skip-button-container > button{
    font-size: 15px;
    text-decoration: underline;
}

.tour-guide__segment9 {
    margin-top: 120px;
}

.tour-guide__mic-button {
    margin-bottom: 40vh;
}

.tour-guide__image {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-bottom: 32px;
}

.tour-guide__image-container {
    max-width: 430px;
    margin-bottom: 16px;
    width: calc(100vw - 90px); // viewwidth minus page side-padding
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tour-guide__image-container > img {
    max-width: 430px;
    width: 100%;
    height: 296px;
    object-fit: cover;
}

.tour-guide__image-footnote {
    font-size: 14px;
}