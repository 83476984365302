@import '../theme';

.message {
    display: flex;
    margin-bottom: 24px;
    word-break: break-word;
    color: #FFFFFF;
}

.message--user {
    flex-direction: row-reverse;
}

.message--user > .message__content-container {
    background: #000;
    border-radius: 18px;
    padding: 8px 12px;
} 

.message__sender {
    font-weight: bold;
    margin-right: 8px;
}

.message__content {
    display: flex;
    flex-direction: column;
}

.message__cue-prompt {
    margin-top: 8px;
    cursor: pointer;
    font-weight: bold;
    color: $color-primary;
    text-decoration: underline;
}