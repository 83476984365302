@import "@fontsource/space-mono";

body {
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  font-family: "Space Mono";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: radial-gradient(213.21% 126.06% at 50% 50%, #EBE9DE 0%, #FFF4FC 100%);
  transition: background-color 0.8s ease-in-out;
}

@media screen and (max-width: 600px) {
  body {
    font-size: 16px;
  }
}

body.dark-theme {
  background: none;
  background-color: #1E1E1E;
  color: #FFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
