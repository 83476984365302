@keyframes scaleIn {
    from {
      transform: translate(-50%, -50%) scale(1, 1);
      opacity: 0.8;
    }
    to {
      transform: translate(-50%, -50%) scale(3, 3);
      opacity: 0;
    }
}

@keyframes slideUp {
    0% {
      transform: translateY(50%);
    }
    100% {
      transform: translateY(0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.audio-mode {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
}

.audio-mode__top-bar {
    position: relative;
    z-index: 3;
    width: 100%;
    display: flex;
    justify-content: end;
}

.audio-mode__close-button-container {
    width: 50px;
}

.audio-mode__container {
    width: 100%;
    height: calc(100vh - 80px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.audio-mode-overlay__icon {
    display: flex;
    justify-content: center;
    position: relative;
}

.audio-mode-overlay__icon--disabled {
    // animation: 1s ease-out 0s 1 slideUp;
    transform: translateY(25%);
}

.audio-mode-overlay__icon-container {
    z-index: 2;
    position: relative;
    // width: 100vw;
    // // height: 90vh;
    // max-height: 1200px;
}

.audio-mode-overlay__pearl-logo {
    position: relative;
    z-index: 2;
    width: unset;
    margin: unset;
}

.audio-mode-overlay__icon-pulse {
    z-index: 1;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: scaleIn 3s infinite cubic-bezier(.36, .11, .89, .32);
}

.audio-mode__close-button {
    z-index: 3;
    color: #FFFFFF;
}

.audio-mode__transcript {
    // display: block;
    // width: calc(100vw - 100px);
    // white-space: nowrap;
    // // direction: rtl;
    // overflow-x: clip;
    // text-align: center;
    // text-overflow: ellipsis;

    white-space: nowrap;
    max-width: calc(100vw - 100px);
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    text-overflow: ellipsis;
}

.audio-mode__guide-text {
    color: #FFFFFF;
    height: 1rem; // This is for when text height goes more than 1 line doesn't shift the whole layout
    margin-top: 24px;
}

.audio-mode__guide-message {
    width: 55vw;
    text-align: center;
}


.audio-mode__ellipse {
    display: flex;
    justify-content: center;
    margin-top: 60px;
}

.audio-mode__footer {
    position: relative;
    z-index: 3;
    margin-bottom: 80px;
}

.audio-mode__footer .button {
    color: #ffffff;
    border-color: #ffffff;
}

.audio-mode__footer .button--disabled {
    color: #333333;
    border-color: #333333;
}

.audio-mode__hold-guide-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    margin-top: -10px;
    pointer-events: none;
    z-index: 3;
    animation: 4s ease-out 0s infinite fadeIn forwards alternate;
    animation-delay: 1s;
}

.audio-mode__hold-guide-icon--hidden {
    animation: 0.5s ease-out 0s 1 fadeOut forwards;
}

.audio-mode__play-button {
    position: relative;
    z-index: 3;
    button {
        color: #DEDEDE;
    }
}

.audio-mode__play-button--hidden {
    visibility: hidden;
}

.audio-mode__loading-message {
    width: max-content;
}

@keyframes loadingDots {
    0%, 20% {
      content: '';
    }
    40% {
      content: '.';
    }
    60% {
      content: '..';
    }
    80%, 100% {
      content: '...';
    }
  }

.audio-mode__loading-message {
    width: 80vw;
    text-align: center;
  }

.audio-mode__loading-message::after {
    content: '...';
    width: 25px;
    display: inline-flex;
    animation: loadingDots 1.5s infinite;
  }
