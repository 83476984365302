.message-input-field {
    display: flex;
    width: 100%;
    align-items: flex-end;
    padding: 8px;
    border: 1px solid #DEDEDE;
    border-radius: 16px;
    margin-bottom: 20px;
}

.message-input-field__button {
    width: 35px;
}

.message-input-field__form {
    width: 100%;
    margin-right: 16px;
    margin-left: 16px;
}

.message-input-field__input-group {
    display: flex;
    align-items: center;    
    padding: 0;
}

.message-input-field__speech-to-text-button {
    margin-right: 4px;
}

.message-input-field__input {
    width: 100%;
    height: 30px;
    margin-right: 8px;
    background-color: unset;
    padding: 0;
    border: none;
    outline: none;
    resize: none;
    color: #DEDEDE;
}

.message-input-field__send-button {
    margin-right: 8px
}