@import '../theme';

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.spinner__icon {
    border-color: $color-primary !important;
    border-right-color: transparent !important;
}