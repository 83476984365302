@keyframes bouncing-loader {
    to {
        opacity: 0.1;
        transform: translateY(-8px);
    }
}

.loading-dots>div:nth-child(2) {
    animation-delay: 0.2s;
}

.loading-dots>div:nth-child(3) {
    animation-delay: 0.4s;
}

.loading-dots {
    display: flex;
}

.loading-dots > div {
    width: 8px;
    height: 8px;
    margin: 3px 6px;
    border-radius: 50%;
    background-color: #a3a1a1;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
}